import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const formSlice = createSlice({
    name: 'form',
    initialState: {
        formName: '',
        data: {},
        valuation: {}
    },
    reducers: {
        updateFormState: (state, action) => {
            console.log('REDUX: UPDATE FORM STATE', state, action)
            const { formName, data } = action.payload;
            if(data) { 
                state.data = data;
                state.formName = formName;
            }
        },
        updateValuationState: (state, action ) => {
            console.log('REDUX: UPDATE ACTION STATE', state, action)
            state.valuation = action.payload
        }
    }

});

export const { updateFormState, updateValuationState } = formSlice.actions
export default formSlice.reducer;
