import { createSlice } from '@reduxjs/toolkit'

const commonState = createSlice({
    name: 'commonState',
    initialState: {
        googleScriptLoaded : false
    },
    reducers: {
        updateGoogleScriptState: (state, action) => {
            const isLoaded = action.payload;
            state.googleScriptLoaded = isLoaded
        },
    },
})

export const { updateGoogleScriptState } = commonState.actions

export default commonState.reducer;